import React from "react";
import Banner from "../Banner/Banner";
import Details from "../Details/Details";
import Challenges from "../Challenges/Challenges";
import About from "../About/About";
import Participants from "../Participants/Participants";

const MainLayout = () => {
  return (
    <>
      <Banner />
      <Details />
      <Challenges />
      <About />
      <Participants />
    </>
  );
};

export default MainLayout;

