import React, { createContext, useContext, useState } from "react";
import enData from "./en.json"; // Import your English language data here
import deData from "./de.json"; // Import your German language data here

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("de");
  const languageData = selectedLanguage === "en" ? enData : deData;

  const changeLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, languageData, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
