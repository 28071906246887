import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./MobileDetails.scss";
import ListInfo from "../listInfo/ListInfo";
import { Award, Begin, Start } from "../../../assets/icons/svg";
import { useLanguage } from "../../../LanguageProvider";

const MobileDetails = () => {
  const { languageData } = useLanguage();

  return (
    <div className="mobileDetails">
      <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
        <ListInfo
          icon={<Start />}
          title={languageData.details.listTitleStart}
          info={languageData.details.listInfoStart}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
        <ListInfo
          icon={<Award />}
          title={languageData.details.listTitleAward}
          info={languageData.details.listAwardInfo}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
        <ListInfo
          icon={<Begin />}
          title={languageData.details.listTitleBegin}
          info={languageData.details.listBeginInfo}
        />
      </ScrollAnimation>
      <div className="mobileDetails__wrapp">
        <img
          src="images/mobile.webp"
          alt="mobile"
          className="mobileDetails__wrapp-img"
        />
      </div>
    </div>
  );
};

export default MobileDetails;
