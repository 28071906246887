import React, { useEffect } from "react";
import "./Privacy.scss";
import { useLanguage } from "../../LanguageProvider";
import { Helmet } from "react-helmet";

const Privacy = () => {
  const { languageData } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Datenschutzrichtlinie - Unterberg Duathlon, Kössen, Österreich
        </title>
        <meta
          name="description"
          content="Lesen Sie unsere Datenschutzrichtlinie für den Unterberg Duathlon in Kössen, Österreich. Erfahren Sie, wie wir Ihre persönlichen Daten schützen und verwalten, einschließlich Informationen zur Datenerfassung, -nutzung und Ihren Rechten als Nutzer."
        />
        <link
          rel="canonical"
          href="https://www.unterberg-duathlon.at/privacy"
        />
      </Helmet>

      <div className="gdpr">
        <div className="gdpr__container">
          <div class="privacy-policy">
            <h2>{languageData.policy.title}</h2>
            <p>{languageData.policy.lastUpdated}</p>

            <h2>{languageData.policy.whoWeAre}</h2>
            <p>{languageData.policy.whoWeAreContent}</p>

            <h2>{languageData.policy.personalDataCollected}</h2>
            <p>{languageData.policy.personalDataCollectedContent}</p>

            <h2>{languageData.policy.purposeOfDataCollection}</h2>
            <p>{languageData.policy.purposeOfDataCollectionContent}</p>

            <h2>{languageData.policy.dataStorageAndSecurity}</h2>
            <p>{languageData.policy.dataStorageAndSecurityContent}</p>

            <h2>{languageData.policy.userRights}</h2>
            <p>{languageData.policy.userRightsContent}</p>

            <h2>{languageData.policy.consent}</h2>
            <p>{languageData.policy.consentContent}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
