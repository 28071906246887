import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./Results.scss";
import { useLanguage } from "../../LanguageProvider";

const Results = () => {
  const { languageData } = useLanguage();

  const results = [
    { year: 2024, file: "/Ergebnisse_2024.pdf" },
    { year: 2023, file: "/Ergebnisse_2023.pdf" }
  ];

  return (
    <div className="results">
      <div className="results__header">
        <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
          <h2 className="results__text">
            {languageData.results.subtitle}
          </h2>
        </ScrollAnimation>
      </div>
      
      <div className="results__list">
        {results.map(result => (
          <ScrollAnimation key={result.year} animateIn="fadeInUp" duration={1} animateOnce={true}>
            <div className="results__item">
              <a href={result.file} target="_blank" rel="noopener noreferrer">
                {result.year}
              </a>
            </div>
          </ScrollAnimation>
        ))}
      </div>

    </div>
  );
};

export default Results;
