import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./Participants.scss";
import { useLanguage } from "../../LanguageProvider";
import { Link } from "react-router-dom";
import { fetchParticipants } from "../../services/ParticipantsService";

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const { languageData } = useLanguage();
  const [daysUntilEvent, setDaysUntilEvent] = useState(0);

  useEffect(() => {
    async function refreshParticipants() {
      const participants = await fetchParticipants();
      if (participants) {
        setParticipants(participants);
      }
    }
    refreshParticipants();
  }, []);

  const calculateDaysUntilEvent = () => {
    const eventDate = new Date("July 12, 2025");
    const currentDate = new Date();
    if (currentDate > eventDate) {
      return 0; // Avoid negative days
    }
    const timeDifference = eventDate - currentDate;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };

  const countUniqueCountries = () => {
    const countries = participants.map((participant) => participant.country);
    const uniqueCountries = new Set(countries);
    return uniqueCountries.size;
  };

  useEffect(() => {
    setDaysUntilEvent(calculateDaysUntilEvent());

    const intervalId = setInterval(() => {
      setDaysUntilEvent(calculateDaysUntilEvent());
    }, 1000 * 60 * 60 * 24); // Update every day

    return () => clearInterval(intervalId);
  }, []);

  const showParticipants = participants.length > 0;

  return (
    <div className="participants">
      <div className="participants__header">
        <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
          <h2 className="participants__title">
            {languageData.registered.title}
          </h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
          <h2 className="participants__text">
            {languageData.registered.text}
          </h2>
        </ScrollAnimation>
      </div>

      <div className="participants__counter">
        {showParticipants && (
          <div className="participants__container-left">
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={1}
              animateOnce={true}
            >
              <div className="participants__counter-quantity">
                {participants.length}
              </div>
              <h2 className="participants__counter-country">
                {languageData.registered.registered}
              </h2>
            </ScrollAnimation>
          </div>
        )}

        {showParticipants && (
          <div className="participants__container-middle">
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={1}
              animateOnce={true}
            >
              <div className="participants__counter-quantity">
                {countUniqueCountries()}
              </div>
              <h2 className="participants__counter-country">
                {languageData.registered.countries}
              </h2>
            </ScrollAnimation>
          </div>
        )}
      </div>

      <div className="participants__countdown">
        <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
          <div
            className="participants__counter-quantity-null pulsate"
            style={{ color: daysUntilEvent === 0 ? "black" : "red" }}
          >
            {daysUntilEvent}
          </div>
          <h2 className="participants__counter-country">
            {languageData.registered.days}
          </h2>
        </ScrollAnimation>
      </div>
      
      {showParticipants && (
        <ScrollAnimation animateIn="animate__zoomIn" duration={2} animateOnce={true}>
          <Link to="/participants">
            <button className="participants__button">
              {languageData.registered.button}
            </button>
          </Link>
        </ScrollAnimation>
      )}
    </div>
  );
};

export default Participants;
