import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import picBanner from "../../assets/images/pic-banner.png";
import "./Banner.scss";
import { useLanguage } from "../../LanguageProvider";

const Banner = () => {
  const { languageData } = useLanguage();
  return (
    <div className="banner">
      <ScrollAnimation animateIn="fadeIn" queueClass="queue" duration={2} animateOnce={true}>
        <div className="banner__wrapper">
          <div className="banner__wrapper-herobanner">
            <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
              <h1 className="banner__wrapper-title">Unterberg Duathlon</h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
              <h2 className="banner__wrapper-date">
                {languageData.banner.date}
              </h2>{" "}
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" duration={3} animateOnce={true}>
              <h2 className="banner__wrapper-address">
                {languageData.banner.address}
              </h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" duration={3} animateOnce={true}>
              <h3 className="banner__wrapper-registration">
                <a
                  href="Ausschreibung_6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="banner__wrapper-registration"
                >
                  {languageData.banner.announcement}
                </a>
              </h3>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUpBig"
              queueClass="queue"
              duration={2}
              animateOnce={true}
            >
              <img
                className="banner__wrapper-picture"
                src={picBanner}
                alt="icon"
                width={235}
              />
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Banner;
