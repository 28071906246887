import React from "react";
import "./ChallengeCard.scss";
import { Link } from "react-router-dom";

const ChallengeCard = (props) => {
  return (
    <Link to={`/registration?discipline=${encodeURIComponent(props.type)}`}>
      <div className="chalCard">
        <h2 className="chalCard__price">{props.price}</h2>
        <p className="chalCard__type">{props.type}</p>
        <p className="chalCard__person">{props.person}</p>
      </div>
    </Link>
  );
};

export default ChallengeCard;
