import React from "react";
import { Facebook, Instagram } from "../../assets/icons/svg";
import hdsports from "../../assets/images/hdsports.png";
import logo from "../../assets/images/logo-mobile.webp";
import { useLanguage } from "../../LanguageProvider";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const { languageData } = useLanguage();
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__copyright">
          Unterberg Duathlon Event, Raiffeisenbank Kössen-Kirchdorf. All Right
          Reserved 2024®
        </div>
        <div className="footer__social">
          <div className="footer__social-item">
            <a
              href="https://www.facebook.com/KoessenerBergduathlon"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social-item"
            >
              <Facebook />
            </a>
          </div>
          <div className="footer__social-item">
            <a
              href="https://www.instagram.com/unterberg_duathlon_/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social-item"
            >
              <Instagram />
            </a>
          </div>
        </div>
        <div className="footer__links">
          <Link to="/privacy">
            <div className="footer__green-link">{languageData.footer.privacy}</div>
          </Link>

          <Link to="/imprint">
            <div className="footer__green-link">
              {languageData.footer.imprint}
            </div>
          </Link>
        </div>
        <div className="footer__hdsports">
          <a
            href="https://www.hdsports.at/triathlons/unterberg-duathlon"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__hdsports"
          >
            <img
              src={hdsports}
              alt="hdsports"
              width="70"
              height="27.5"
              className="footer__hdsports-img"
              loading="lazy"
            />
          </a>
        </div>
        <div className="footer__logo">
        <a
            href="https://www.raiffeisen.at/tirol/koessen-kirchdorf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__logo"
          >
          <img 
            src={logo} 
            width="34.5"
            height="34.5"
            alt="logo" 
            className="footer__logo-img" 
            loading="lazy" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
