import React, { useState, useEffect } from "react";
import { Table } from "antd";

import "./ParticipantsList.scss";
import "../../index.scss";
import { ParticipantsColumns } from "./components/ParticipantsColums";
import { ParticipantsMobileColumns } from "./components/ParticipantsMobileColumns";
import { fetchParticipants } from "../../services/ParticipantsService";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageProvider";

export const ParticipantsList = () => {
  const [participants, setParticipants] = useState([]);
  const { languageData } = useLanguage();
  const [ isMobile ] = useState(window.innerWidth < 768); // Assuming 768px as a breakpoint for mobile devices
  

  const navigate = useNavigate();
  const reloadAndNavigate = () => {
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    async function refreshParticipants() {
      let participants = await fetchParticipants();
      if (participants) {
        setParticipants(participants);
      }
    }
    refreshParticipants();
  }, []);

  return (
    <div className="participantslist">
      <div className="participantslist-container">
        <h2 className="participantslist__container-title">
          {languageData.participants.title}
        </h2>
        <p className="participantslist__container-text">
          {languageData.participants.subtitle1} {participants.length}{" "}
          {languageData.participants.subtitle2}
        </p>
        <Table
          className="participantsTable"
          dataSource={participants}
          columns={isMobile ? ParticipantsMobileColumns : ParticipantsColumns}
          pagination={{ defaultPageSize: 100, showSizeChanger: false }}
          size="small"
          rowKey="last_name"
        />
        <Link onClick={reloadAndNavigate}>
          <button className="participantslist__container-button">
            {languageData.participants.button_back}
          </button>
        </Link>
      </div>
    </div>
  );
};
