import React from "react";
import "./ListInfo.scss";

const ListInfo = (props) => {
  return (
    <div className="listInfo">
      <div className="listInfo__icon">{props.icon}</div>
      <h2 className="listInfo__title">{props.title}</h2>
      <p className="listInfo__info">{props.info}</p>
    </div>
  );
};

export default ListInfo;
