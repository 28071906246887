import axios from "axios";
import { BACKEND_URL } from "../utils/serverData";

export const fetchParticipants = async () => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/participants/list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      response.data.participants.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return response.data.participants;
    }
  } catch (error) {
    console.error("Error fetching participants: " + error);
  }
};

export const addParticipants = async (formData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/participants/add`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
