import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import about from "../../assets/images/about.webp";
import "./About.scss";
import { useLanguage } from "../../LanguageProvider";

const About = () => {
  const { languageData } = useLanguage();
  return (
    <div className="about">
      <div className="about__container">
        <div className="about__container-left">
          <ScrollAnimation animateIn="animate__zoomIn" duration={1} animateOnce={true}>
            <img src={about} alt="img about" className="about__img" />
          </ScrollAnimation>
        </div>
        <div className="about__container-right">
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <h2 className="about__title">{languageData.about.title}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <p className="about__info">{languageData.about.info}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <p className="about__text">{languageData.about.text}</p>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default About;
