export const countries = [
  { code: "at", name: "Österreich" },
  { code: "ch", name: "Schweiz" },
  { code: "de", name: "Deutschland" },
  { code: "fr", name: "Frankreich" },
  { code: "it", name: "Italien" },
  { code: "pl", name: "Polen" },
  { code: "cz", name: "Tschechische Republik" },
  { code: "hu", name: "Ungarn" },
  { code: "nl", name: "Niederlande" },
  { code: "be", name: "Belgien" },
  { code: "se", name: "Schweden" },
  { code: "no", name: "Norwegen" },
  { code: "dk", name: "Dänemark" },
  { code: "fi", name: "Finnland" },
  { code: "gr", name: "Griechenland" },
  { code: "ie", name: "Irland" },
  { code: "se2", name: "Schweden" },
  { code: "us", name: "Vereinigte Staaten" },
  { code: "ca", name: "Kanada" },
  { code: "mx", name: "Mexiko" },
  { code: "gb", name: "Vereinigtes Königreich" },
  { code: "es", name: "Spanien" },
  { code: "pt", name: "Portugal" }
];
