import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ModalInfo from "../modals/ModalInfo";
import "./Registration.scss";
import { useLanguage } from "../../LanguageProvider";
import { addParticipants } from "../../services/ParticipantsService";
import TeamMemberForm from "./TeamMemberForm";
import { Helmet } from "react-helmet";

const Registration = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const disciplineFromQuery = query.get("discipline");

  const [formData, setFormData] = useState({
    club: "",
    discipline: "",
    teamMembers: [
      {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        gender: "",
        country: "",
        city: "",
        dateOfBirth: "",
      },
    ],
  });

  useEffect(() => {
    if (disciplineFromQuery) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        discipline: disciplineFromQuery,
      }));
    }
  }, [disciplineFromQuery]);

  const { languageData } = useLanguage();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);

  const [infoTitle, setInfoTitle] = useState(
    languageData.registration.success_title
  );
  const [infoText, setInfoText] = useState(
    languageData.registration.success_subtitle
  );
  const [isSuccess, setIsSuccess] = useState(true);

  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTeamMemberChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTeamMembers = [...formData.teamMembers];
    updatedTeamMembers[index][name] = value;

    setFormData({
      ...formData,
      teamMembers: updatedTeamMembers,
    });
  };

  const addTeamMember = () => {
    setFormData({
      ...formData,
      teamMembers: [
        ...formData.teamMembers,
        {
          firstName: "",
          lastName: "",
          email: "",
          role: "",
          gender: "",
          country: "",
          city: "",
          dateOfBirth: "",
        },
      ],
    });
  };

  const removeTeamMember = (index) => {
    const updatedTeamMembers = [...formData.teamMembers];
    updatedTeamMembers.splice(index, 1);
    setFormData({
      ...formData,
      teamMembers: updatedTeamMembers,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Age validation
    const today = new Date();
    let ageValidationPassed = true;
    formData.teamMembers.forEach((member) => {
      const birthDate = new Date(member.dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 8 || age > 100) {
        ageValidationPassed = false;
      }
    });

    if (!ageValidationPassed) {
      setInfoTitle(languageData.registration.ageValidationAlert);
      setInfoText(languageData.registration.ageValidationAlertText);
      setIsSuccess(false);
      setIsModalSuccessOpen(true);
      return;
    }

    // Check if club field is empty when discipline is "Duathlon Team"
    if (formData.discipline === "Duathlon Team" && !formData.club) {
      setInfoTitle(languageData.registration.alert);
      setInfoText(languageData.registration.defineClub);
      setIsSuccess(false);
      setIsModalSuccessOpen(true);
      return;
    }

    try {
      await addParticipants(formData);

      setInfoTitle(languageData.registration.success_title);
      setInfoText(languageData.registration.success_subtitle);
      setIsSuccess(true);
      setIsModalSuccessOpen(true);
    } catch (error) {
      if (error.response?.data?.error_code === "ALREADY_EXISTS") {
        setInfoTitle(languageData.registration.alert);
        setInfoText(languageData.registration.alreadyExists);
        setIsSuccess(false);
        setIsModalSuccessOpen(true);
      } else {
        alert(error.response?.data?.message);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalSuccessOpen(false);
    if (isSuccess) {
      window.location.href = "/participants";
    }
  };

  const handlePolicyCheckboxChange = (e) => {
    setIsPolicyAccepted(e.target.checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Anmeldung zum Unterberg Duathlon 2025 in Kössen, Österreich -
          Registrieren Sie sich jetzt!
        </title>
        <meta
          name="description"
          content="Melden Sie sich jetzt für den Unterberg Duathlon 2025 an! Egal, ob Sie allein antreten oder als Teil eines Teams, sichern Sie sich Ihren Platz beim herausfordernden Duathlon in Kössen, Österreich. Finden Sie alle Informationen zur Registrierung, den Disziplinen und wie Sie sich als Athlet für den Wettkampf vorbereiten können."
        />
        <link
          rel="canonical"
          href="https://www.unterberg-duathlon.at/registration"
        />
      </Helmet>
      <div className="registration">
        <div className="registration__container">
          <h2 className="registration__container-title">
            {languageData.registration.registration}
          </h2>
          <form onSubmit={handleSubmit} className="registration__form">
            <div className="registration__form-discipline">
              <div className="registration__form-wrapp">
                <label htmlFor="role" className="registration__form-title">
                  {languageData.registration.discipline}:
                </label>
                <select
                  id="discipline"
                  name="discipline"
                  value={formData.discipline}
                  onChange={handleChange}
                  required
                  className="registration__form-input"
                >
                  <option value="" disabled>
                    {languageData.registration.selectYourDiscipline}
                  </option>
                  <option value="Duathlon Einzel">
                    {languageData.challenge.typeSingle}
                  </option>
                  <option value="Duathlon Team">
                    {languageData.challenge.typeTeam}
                  </option>
                  <option value="Berglauf">
                    {languageData.challenge.typeMountain}
                  </option>
                  <option value="Mountainbike-Rennen">
                    {languageData.challenge.typeBike}
                  </option>
                </select>
              </div>
              <div className="registration__form-wrapp">
                {" "}
                <label className="registration__form-title">
                  {languageData.registration.club}:
                </label>
                <input
                  type="text"
                  id="club"
                  name="club"
                  value={formData.club}
                  onChange={handleChange}
                  className="registration__form-input"
                />
              </div>
            </div>

            {formData.teamMembers.map((teamMember, index) => (
              <TeamMemberForm
                key={`teamMember-${index}`}
                discipline={formData.discipline}
                teamMember={teamMember}
                handleTeamMemberChange={(e) => handleTeamMemberChange(index, e)}
                index={index}
              />
            ))}

            {formData.discipline === "Duathlon Team" &&
              formData.teamMembers.length < 2 && (
                <button
                  type="button"
                  onClick={addTeamMember}
                  className="registration__form-addBtn"
                >
                  + {languageData.registration.addTeamMember}
                </button>
              )}

            {formData.discipline === "Duathlon Team" &&
              formData.teamMembers.length >= 2 && (
                <button
                  type="button"
                  onClick={() =>
                    removeTeamMember(formData.teamMembers.length - 1)
                  }
                  className="registration__form-removeBtn"
                >
                  - {languageData.registration.removeTeamMember}
                </button>
              )}

            <div className="registration__form-policy">
              <input
                type="checkbox"
                id="policyAccepted"
                checked={isPolicyAccepted}
                onChange={handlePolicyCheckboxChange}
              />
              <label
                htmlFor="policyAccepted"
                className="registration__form-policyLabel"
              >
                {languageData.registration.acceptPolicyAndRules}
              </label>
            </div>

            <button
              type="submit"
              className="registration__form-btn"
              disabled={!isPolicyAccepted}
            >
              {languageData.registration.register}
            </button>

            <ModalInfo
              title={infoTitle}
              text={infoText}
              isSuccess={isSuccess}
              isModalOpen={isModalSuccessOpen}
              closeModal={() => handleCloseModal()}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Registration;
