import React, { useState, useEffect } from "react";
import logo from "../../assets/images/Raiffeisenbank.svg";
import logomob from "../../assets/images/logo-mobile.webp";
import ukFlag from "../../assets/images/UK-flag.webp";
import atFlag from "../../assets/images/AT-flag.webp";
import "./Header.scss";
import { Mail, Phone } from "../../assets/icons/svg";
import enData from "../../en.json";
import deData from "../../de.json";
import { useLanguage } from "../../LanguageProvider";
import { Link } from "react-router-dom";

export const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const { selectedLanguage, changeLanguage } = useLanguage();

  const handleLanguageChange = () => {
    const newLanguage = selectedLanguage === "en" ? "de" : "en";
    changeLanguage(newLanguage);
    updateDialogFlowLanguage(newLanguage);
  };

  const updateDialogFlowLanguage = (newLanguage) => {
    const dfMessenger = document.querySelector('df-messenger');
    if (dfMessenger) {
      dfMessenger.setAttribute('language-code', newLanguage);
      dfMessenger.render();
    }
  };

  const languageData = selectedLanguage === "en" ? enData : deData;

  return (
    <div className={`header ${visible ? "" : "header-hidden"}`} id="header">
      <div className="header__wrapper">
        <div className="header__wrapper-menu">
          <div className="header__logo-wrapper">
            <Link to="/">
              <img className="header__logo" src={logo} alt="icon" />
              <img className="header__logo-mob" src={logomob} alt="icon" />
            </Link>
          </div>
          <div className="header__menu">
            <div className="header__menu-icon">
              <a href={`mailto:unterbergduathlon.bsvkoessen@gmail.com`} target='_blank' rel='noreferrer'>
                <Mail />
              </a>
            </div>
            <div className="header__menu-col">
              <p className="header__menu-title">
                {languageData.header.contactEmail}
              </p>
              <p className="header__menu-info">
                <a href={`mailto:unterbergduathlon.bsvkoessen@gmail.com`} target='_blank' rel='noreferrer'>
                  unterbergduathlon.bsvkoessen@gmail.com
                </a>
              </p>
            </div>
          </div>
          <div className="header__menu">
            <div className="header__menu-icon">
              <a href="tel:+4369918123736">
                <Phone />
              </a>
            </div>
            <div className="header__menu-col">
              <p className="header__menu-title">Christoph Mühlberger</p>
              <a href="tel:+4369918123736">
                <p className="header__menu-info">+43/699/18123736</p>
              </a>
            </div>
          </div>
          <div className="header__menu">
            <Link to="/results">
              <p className="header__menu-item">{languageData.header.results}</p>
            </Link>
          </div>
          <div className="header__menu">
            <Link to="/registration">
              <p className="header__menu-item">{languageData.header.register}</p>
            </Link>
          </div>
          <div className="header__menu">
            <img
              className="header__img-lang"
              src={selectedLanguage === "en" ? ukFlag : atFlag}
              alt="Language Flag"
              onClick={handleLanguageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
