import React from "react";
import { useLanguage } from "../../LanguageProvider";
import { countries } from "./utils";

const TeamMemberForm = ({ discipline, teamMember, handleTeamMemberChange, index }) => {
  const { languageData } = useLanguage();

  return (
    <div className="registration__form-addwrapp">
      <div className="registration__form-name">
        <div className="registration__form-wrapp">
          <input
            type="text"
            id={`firstName-${index}`}
            name="firstName"
            value={teamMember.firstName}
            onChange={handleTeamMemberChange}
            required
            className="registration__form-input"
            placeholder={languageData.registration.firstName}
          />
        </div>
        <div className="registration__form-wrapp">
          <input
            type="text"
            id={`lastName-${index}`}
            name="lastName"
            value={teamMember.lastName}
            onChange={handleTeamMemberChange}
            required
            className="registration__form-input"
            placeholder={languageData.registration.lastName}
          />
        </div>
      </div>

      <input
        type="email"
        id={`email-${index}`}
        name="email"
        value={teamMember.email}
        onChange={handleTeamMemberChange}
        required
        className="registration__form-date"
        placeholder={languageData.registration.email}
      />

      <div className="registration__form-name">
        <div className="registration__form-wrapp">
          <select
            id={`gender-${index}`}
            name="gender"
            value={teamMember.gender}
            onChange={handleTeamMemberChange}
            className="registration__form-input"
            required
          >
            <option value="" disabled>
              {languageData.registration.selectYourGender}
            </option>
            <option value="M">{languageData.registration.male}</option>
            <option value="W">{languageData.registration.female}</option>
            <option value="X">{languageData.registration.diverse}</option>
          </select>
        </div>

        { discipline === "Duathlon Team" && (
          <div className="registration__form-radio">
            <label className="registration__form-radio-label">
              <input
                id={`biker-${index}`}
                type="radio"
                name={`role-${index}`}
                value="biker"
                //   checked={teamMember.role === "biker"}
                onChange={handleTeamMemberChange}
              />
              {languageData.registration.biker}
            </label>
            <label className="registration__form-radio-label">
              <input
                id={`runner-${index}`}
                type="radio"
                name={`role-${index}`}
                value="runner"
                //   checked={teamMember.role === "runner"}
                onChange={handleTeamMemberChange}
              />
              {languageData.registration.runner}
            </label>
          </div>
        )}
      </div>

      <div className="registration__form-name">
        <div className="registration__form-wrapp">
          <select
            id={`country-${index}`}
            name="country"
            value={teamMember.country}
            onChange={handleTeamMemberChange}
            required
            className="registration__form-input"
          >
            <option value="" disabled>
              {languageData.registration.selectYourCountry}
            </option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="registration__form-wrapp">
          <input
            type="text"
            id={`city-${index}`}
            name="city"
            value={teamMember.city}
            onChange={handleTeamMemberChange}
            required
            className="registration__form-input"
            placeholder={languageData.registration.city}
          />
        </div>
      </div>

      <label htmlFor="dateOfBirth" className="registration__form-label-data">
        {languageData.registration.dateOfBirth}:
      </label>

      <input
        type="date"
        id={`dateOfBirth-${index}`}
        name="dateOfBirth"
        value={teamMember.dateOfBirth}
        onChange={handleTeamMemberChange}
        required
        className="registration__form-date"
      />
    </div>
  );
};

export default TeamMemberForm;
