import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useLanguage } from "../../LanguageProvider";
import ListInfo from "./listInfo/ListInfo";
import "./Details.scss";

import { Award, Begin, Start } from "../../assets/icons/svg";

import MobileDetails from "./MobileDetails/MobileDetails";

const Details = () => {
  const { languageData } = useLanguage();

  return (
    <div className="details">
      <div className="details__wrapp">
        <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
          <h2 className="details__wrapp-title">{languageData.details.title}</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
          <p className="details__wrapp-text">{languageData.details.text}</p>
        </ScrollAnimation>

        <div className="details__desktop">
          <div className="details__desktop-info">
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={2}
              animateOnce={true}
            >
              <ListInfo
                icon={<Start />}
                title={languageData.details.listTitleStart}
                info={languageData.details.listInfoStart}
              />{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={2}
              animateOnce={true}
            >
              <ListInfo
                icon={<Award />}
                title={languageData.details.listTitleAward}
                info={languageData.details.listAwardInfo}
              />{" "}
            </ScrollAnimation>
          </div>
          <div className="details__desktop_imgWrapp">
            <img
              src="images/map.webp"
              alt="map"
              className="details__desktop_mapImg"
            />
          </div>
          <div className="details__desktop-infoEnd">
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={2}
              animateOnce={true}
            >
              <ListInfo
                icon={<Begin />}
                title={languageData.details.listTitleBegin}
                info={languageData.details.listBeginInfo}
              />{" "}
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <MobileDetails />
    </div>
  );
};

export default Details;
