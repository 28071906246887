export const headerStyle = {
  color: "#343642",
  textTransform: "uppercase",
  fontSize: 14,
  fontWeight: "semi-bold",
  marginRight: 50
};

export const valueStyle = {
  color: "#343642",
  fontSize: 16,
  marginRight: 50
};

export const headerNameStyle = {
  color: "#343642",
  textTransform: "uppercase",
  fontSize: 14,
  fontWeight: "semi-bold",
  marginRight: 50,
  marginLeft: 20
};

export const nameStyle = {
  color: "#343642",
  fontSize: 16,
  fontWeight: "bold",
  marginRight: 100,
  marginLeft: 20
};