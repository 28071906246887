import React, { useEffect } from "react";
import "./Impressum.scss";
import { Helmet } from "react-helmet";

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Unterberg Duathlon in Kössen, Österreich - Offizielle
          Informationen
        </title>
        <meta
          name="description"
          content="Offizielle Seite des Unterberg Duathlon. Alle Details zum Event in Kössen, Österreich. Anmeldungen, Streckeninfos, Veranstalterkontakte und mehr. Melden Sie sich bis zum 10. Juli online an und seien Sie Teil dieses herausfordernden Wettkampfs in malerischer Umgebung."
        />
        <link rel="canonical" href="https://www.unterberg-duathlon.at/imprint" />
      </Helmet>
      <div className="imprint">
        <div className="imprint__container">
          <div>
            <h2>Impressum</h2>

            <h4>Veranstalter: Bergsportverein Kössen</h4>
            <h4>E-mail: unterbergduathlon.bsvkoessen@gmail.com</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impressum;
