import React from "react";
import { headerStyle, valueStyle } from "../../../utils/text";
import "./ParticipantsColumns.css";

export const ParticipantsMobileColumns = [
  {
    title: <span style={headerStyle}>Nachname</span>,
    dataIndex: "last_name",
    key: "last_name",
    render: (last_name) => {
      return <div style={valueStyle}>{last_name}</div>;
    },
  },
  {
    title: <span style={headerStyle}>Stadt</span>,
    dataIndex: "city",
    key: "city",
    render: (city) => {
      return <div style={valueStyle}>{city}</div>;
    },
  },
];
