import React from "react";
import { useNavigate } from "react-router-dom";

// Define the NotFound component
const NotFound = () => {
  const navigate = useNavigate();

  const containerStyle = {
    maxWidth: "404px",
    padding: "20px",
  };

  const buttonStyle = {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "rgba(255, 250, 148, 0.9)",
    color: "#000",
    textDecoration: "none",
    borderRadius: "5px",
    fontSize: "18px",
    marginTop: "20px",
    cursor: "pointer",
  };

  const reloadAndNavigate = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div
      style={{
        height: "100vh",
        margin: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Arial", sans-serif',
        textAlign: "center",
      }}
    >
      <div style={containerStyle}>
        <h2>Huch! Seite nicht gefunden.</h2>
        <p>Wir können die von Ihnen gesuchte Seite nicht finden.</p>
        <img
          src="/images/404.webp"
          alt="Mann auf der Suche nach dem Start bei einem Duathlon"
          style={{ maxWidth: "400px", height: "auto", margin: "20px 0" }}
        />
        <button style={buttonStyle} onClick={() => reloadAndNavigate()}>
          Startseite
        </button>
      </div>
    </div>
  );
};

export default NotFound;
