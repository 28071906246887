// ModalContent.js
import React from "react";
import Modal from "react-modal";
import "./ModalInfo.scss";
import { useLanguage } from "../../LanguageProvider";

const ModalInfo = ({ title, text, isSuccess, isModalOpen, closeModal }) => {
  const { languageData } = useLanguage();

  return (
    <Modal
      appElement={document.getElementById("root")}
      className={`modal ${isSuccess ? 'modal-success' : 'modal-failure'}`}
      isOpen={isModalOpen}
      onRequestClose={closeModal}
    >
      <div className="modal__container">
        <h2 className="modal__title">
          {title}
        </h2>
        <p className="modal__subheader">
          {text}
        </p>
        <button onClick={closeModal} className="modal__close-btn">
          {languageData.registration.close}
        </button>
      </div>
    </Modal>
  );
};

export default ModalInfo;
