import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import { LanguageProvider } from "./LanguageProvider";
import MainLayout from "./components/MainLayout/MainLayout";
import Registration from "./components/Registration/Registration";
import Results from "./components/Results/Results";
import { Header } from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { ParticipantsList } from "./components/ParticipantsList/ParticipantsList";
import Privacy from "./components/Privacy/Privacy";
import Impressum from "./components/Impressum/Impressum";
import NotFound from './components/NotFound/NotFound';
import { Helmet } from 'react-helmet'


function App() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200); // Adjust delay as necessary
  }, []);

  return (
    <Router>
      <Helmet>
        <title>Unterberg Duathlon - Wettbewerb in Österreich</title>
        <meta name='description' content={"Unterberg Duathlon: 12. Juli 2025 um 11:00 Uhr. Online Anmeldeschluss: 10. Juli."} />
        <link rel="canonical" href="https://www.unterberg-duathlon.at" />
      </Helmet>
      <div id="root" className="App">
        <LanguageProvider>
          <Header />
          <Routes>
            <Route path="/" element={<MainLayout />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/participants" element={<ParticipantsList />} />
            <Route path="/results" element={<Results />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/imprint" element={<Impressum />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </LanguageProvider>
      </div>
    </Router>
  );
}

export default App;
