import React from "react";
import { headerStyle, valueStyle, headerNameStyle, nameStyle } from "../../../utils/text";
import "./ParticipantsColumns.css";

export const ParticipantsColumns = [
  {
    title: <span style={headerNameStyle}>Name</span>,
    key: "name",
    render: (record) => {
      return <div style={nameStyle}>{record.first_name} {record.last_name}</div>;
    },
  },
  {
    title: <span style={headerStyle}>Verein</span>,
    dataIndex: "club",
    key: "club",
    render: (club) => {
      return <div style={valueStyle}>{club}</div>;
    },
  },
  {
    title: <span style={headerStyle}>Disziplin</span>,
    dataIndex: "discipline",
    key: "discipline",
    render: (discipline) => {
      return <div style={valueStyle}>{discipline}</div>;
    },
  },
  {
    title: <span style={headerStyle}>Land</span>,
    dataIndex: "country",
    key: "country",
    render: (country) => {
      return <div style={valueStyle}>{country}</div>;
    },
  },
  {
    title: <span style={headerStyle}>Stadt</span>,
    dataIndex: "city",
    key: "city",
    render: (city) => {
      return <div style={valueStyle}>{city}</div>;
    },
  },
];
